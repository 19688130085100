"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.titleCase = exports.hashCode = exports.snakeToKebab = exports.makeUriFriendly = exports.camelToKebabCase = exports.kebabToSnake = exports.kebabToCamelCase = exports.isDefined = exports.capitalizeFirstLetter = exports.uuidRegExp = void 0;
const string_helpers_internal_1 = require("./string-helpers-internal");
exports.uuidRegExp = '[0-9a-f]{8}-[0-9a-f]{4}-[0-8][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}';
/**
 * Accepts a string and capitalizes its first letter. If the value is not a string,
 * the value provided, will be returned, unchanged
 * @param value - the string to update
 * @returns - the provided string with its first letter capitalized
 */
function capitalizeFirstLetter(value) {
    return typeof value === 'string'
        ? (value.charAt(0).toUpperCase() + value.slice(1))
        : value;
}
exports.capitalizeFirstLetter = capitalizeFirstLetter;
/**
 * Checks if the given value is defined and not empty.
 */
function isDefined(input) {
    return typeof input === 'string' && input !== '';
}
exports.isDefined = isDefined;
/**
 * Converts a kebab-cased string to a camelCased string.
 * @param kebabCase to be converted from kebab-case
 * @returns camelCased version of `kebabCase` input.
 */
function kebabToCamelCase(kebabCase) {
    return kebabCase.replace(/-[a-zA-Z]/g, (match) => match[1] ? match[1].toUpperCase() : '');
}
exports.kebabToCamelCase = kebabToCamelCase;
/**
 * Replaces kebabs (`-`) with snakes (`_`) in a string. This isn't a proper
 * conversion to snake case as it does not change the casing of any characters.
 */
function kebabToSnake(kebab) {
    return kebab.replaceAll('-', '_');
}
exports.kebabToSnake = kebabToSnake;
/**
 * Converts a camelCased string to a kebab-cased version. Always converts to
 * lowercase even if the input string is not kebab cased.
 *
 * **WARN** The type signature is naive. It workds for simple CamelCased strings
 * that do not have sequential capital letters or spaces/underscores but breaks
 * down for more complex strings. Be aware the signature may be lying about the
 * return value.
 *
 * @param camelCase input to be converted from camelCase
 * @returns kebab-cased version of the `camelCase` input.
 */
function camelToKebabCase(camelCase) {
    const words = camelCase.split(/[_ -]/);
    const camelCaseRegex = /[A-Z]+/g;
    return words
        .filter((word) => word.length > 0)
        .map((word) => {
        const wordResult = word.replace(camelCaseRegex, (match, startIndex) => startIndex === 0 ? match.toLowerCase() : `-${match.toLowerCase()}`);
        return wordResult;
    })
        .join('-');
}
exports.camelToKebabCase = camelToKebabCase;
/**
 * Composes `stripNonUriChars` and `camelToKebabCase` to change the given
 * `input` into a URI friendly string.
 * @see camelToKebabCase
 * @see stripNonUriChars
 */
function makeUriFriendly(input) {
    return camelToKebabCase((0, string_helpers_internal_1.stripNonUriChars)(input));
}
exports.makeUriFriendly = makeUriFriendly;
/**
 * Replaces snakes (`_`) with kebabs (`-`) in a string. This isn't a proper
 * conversion to kebab case as it does not change the casing of any characters.
 */
function snakeToKebab(kebab) {
    return kebab.replaceAll('_', '-');
}
exports.snakeToKebab = snakeToKebab;
/**
 * Returns a 32bit integer hash code from a string
 * @see https://stackoverflow.com/a/8831937
 */
function hashCode(value) {
    let hash = 0;
    if (typeof value !== 'undefined') {
        for (let i = 0, len = value.length; i < len; i++) {
            const chr = value.charCodeAt(i);
            hash = (hash << 5) - hash + chr;
            hash |= 0; // Convert to 32bit integer
        }
    }
    return `${hash}`;
}
exports.hashCode = hashCode;
/**
 * Transforms a string to be Title Cased.
 * For example - "some example string" becomes "Some Example String"
 *
 * @param str The string to title case
 * @returns The string in Title Case
 */
function titleCase(str) {
    const splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
        const subStr = splitStr[i];
        if (!subStr) {
            throw new Error(`Character at ${i} of ${str} is not defined.`);
        }
        splitStr[i] = subStr.charAt(0).toUpperCase() + subStr.substring(1);
    }
    return splitStr.join(' ');
}
exports.titleCase = titleCase;
